import React from "react"
import { graphql, Link } from "gatsby"

import Seo from '../components/SEO'

const Post = ({ data }) => {
    const { markdownRemark: { frontmatter, html } } = data
  const { title, description, image } = frontmatter
    return (
      <div className="wrapper">
        <header className="header">
          <Link to='/' className="header-link">Warp Barrel</Link>
        </header>
        <Seo title={title} description={description} image={image}></Seo>
        <article>
          <h1>{title}</h1>
          <p>{frontmatter.date}</p>
          <section className="article-body" dangerouslySetInnerHTML={{ __html: html }} />
        </article>
      </div>
    )
}

export default Post

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        slug
        title
        description
        image
      }
    }
  }
`